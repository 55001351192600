/* eslint-disable no-unsafe-optional-chaining */
import { graphql, useStaticQuery } from "gatsby"
// import { isEmpty } from "lodash"

const useLimitSignatureProperties = () => {
  const data = useStaticQuery(graphql`
    query {
      singnatureProps: allProperties(
        limit: 6
        sort: { fields: createdAt, order: DESC }
        filter: { publish: { eq: true }, price: { gte: 20000000 }, department: {eq: "residential"}, search_type: {eq: "sales"} }
      ) {
        nodes {
          ...propertyCardFragment
        }
      }
    }
  `)

  const propertiesSing = data?.singnatureProps?.nodes

  return {
    properties: [...propertiesSing],
    propertiesSing,
  }
}

export default useLimitSignatureProperties

import Breadcrumbs from "@components/Breadcrumbs/Breadcrumbs"
import React from "react"
import "./Banner.scss"
import BannerBG from "./BannerBG"
import BannerContent from "./BannerContent"

const BannerHome = ({
  bannerData,
  ggfx_results,
  strapiID,
  menuData,
  centerContent,
}) => {
  return (
    <div className="banner-wrap banner-home-wrap">
      <BannerBG />
      {menuData && <Breadcrumbs menuData={menuData} />}

      <BannerContent
        bannerData={bannerData}
        ggfx_results={ggfx_results}
        strapiID={strapiID}
        centerContent={centerContent}
      />
    </div>
  )
}

export default BannerHome
